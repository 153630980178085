@import '../App.scss';

%button {
  width: 100%;
  height: 3.5em;
  font-family: $font;
  box-shadow: none;
  text-transform: none;
}

%main_heading {
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  color: $heading;
  padding: 0;
  margin: 0;
}

#landing_modal {
  &__container {
    height: inherit;
  }
  
  &__sub_container {
    display: flex;
    height: calc(100% - 3.5em);
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2.86em 1.72em 2em 1.72em;

    @media only screen and (max-height:600px) and (min-width: 320px) and (max-width: 1025px) {
      height: calc(100% - 5.5em);
    }

    @media only screen and (max-device-width: 480px) and (max-height: 899px) and (min-height: 601px) {
      height: calc(100% - 3.5em)
    }
  }

  &__heading {
    @extend %main_heading;
  }

  &__button {
    @extend %button;

    &--enabled {
      @extend %button;
      background-color: $primaryColor;
      color: white;
    }

    &--disabled {
      @extend %button;
      background-color: rgba(5, 5, 82, 0.2);
      color: white;
    }
  }
}

#response_modal {
  &__container {
    height: inherit;
  }

  &__sub_container {
    height: calc(100% - 1.5em);
    padding: 5.75em 1.72em 2em 1.72em;

    @media only screen and (max-height:600px) and (min-width: 320px) and (max-width: 1025px) {
      height: calc(100% - 3.5em);
    }
  }

  &__heading {
    @extend %main_heading;
    margin-bottom: 1.2em;
  }

  &__subText {
    font-size: 14px;
    color: $subHeading;
    line-height: 1.71;
    text-align: left;
  }

  &__redirect{
    font-size: 14px;
    line-height: 1.71;
    font-style: italic;
    color: $primaryColor;
  }
}

#loader {
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader{
  border: 6px solid #554ef1;
  border-radius: 50%;
  border-top: 6px solid #f7f6fe;
  border-right: 6px solid #f7f6fe;
  border-left: 6px solid #f7f6fe;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  position: absolute;
  top: 37%;
  left: 33.25%;
}
.loader-box{
  height: 120px;
  width: 120px;
}
