@import url("https://fonts.googleapis.com/css?family=Lato");

$font: Lato;
$heading: #050552;
$subHeading: rgba(5, 5, 82, 0.8);
$subText: rgba(5, 5, 82, 0.6);
$hover: rgba(85, 78, 241, 0.1);
$primaryColor: #554ef1;
$footer: rgba(5, 5, 82, 0.03);
$background: #fff;
$backdrop: rgba(7, 8, 23, 0.85);

.App {
  text-align: center;
  margin: 0;
  padding: 0;
  font-family: $font;
}
