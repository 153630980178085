@import "../../App.scss";

#redirect {
  &__msg {
    font-size: 14px;
    line-height: 1.71;
    font-style: italic;
    color: $primaryColor;
  }
}

#footer {
  &__container {
    height: 3.5em;
    display: flex;
    background-color: $footer;
    justify-content: space-between;
    align-items: center;
    padding: 0 2.5em;
  }
  
  &__item_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 12px;
      color: $subHeading;
      margin-left: 0.6em;
    }
  }
}

#animation {
  svg {
    height: 22em !important;
    width: 22em !important;
  }
}
