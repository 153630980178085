@import '../App.scss';

.hv-modal {
  background-color: transparent;
}

#kyc {
  &_page {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: $backdrop;
  };
  
  &_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color : $heading;
    font-size: 14px;
    font-weight: normal;
    width: 350px;
    height: 623px;
    overflow: hidden;
    border-radius: 5px;
    background-color: $background;

    @media (min-width:320px) and (max-width:1025px){
      max-height: 100vh;
    } 

    @media only screen and (max-height:899px) and (min-height:601px) {
      transform: translate(-50%, -50%) scale(1);
    }

    @media only screen and (max-height:600px) {
      transform: translate(-50%, -50%) scale(0.90);
    }

    @media only screen and (max-device-width: 480px) {
      height: 100%;
      width: 100%;
    }
  }
}
